import { commercePost } from '../../lib/commerce/common/base-commerce-api';
import { SfResponse } from '../../lib/common/model/common-models';
import { getCartId } from '../cart/cart-provider';
import {
  FastSpringCheckoutData,
  FastSpringEncryptedData,
  FastSpringCompleteSessionData,
} from './checkout-models';
import { isNonOkResponse } from 'lib/common/common-http-client';
import { getAccountId } from 'lib/authentication/account-provider';
import { FastSpringContactAddress } from './checkout-slice';

export const createFastSpringCheckoutSession = async (
  billingDetails: FastSpringContactAddress,
  store: string,
  cartId: string,
  accountId: string,
  bearerToken: string
): Promise<SfResponse<FastSpringCheckoutData>> => {
  const response = await commercePost<SfResponse<FastSpringCheckoutData>>(
    '/v1/checkout/fs/start',
    {
      cartId: cartId,
      effectiveAccountId: accountId,
      store: store,
      contact: billingDetails,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    const results = response.data;
    if (results == null) {
      throw new Error('Failed to create fast spring checkout session');
    }

    const payload = results as FastSpringCheckoutData;
    if (!payload.fastSpringSession) {
      throw new Error('Failed to create fast spring checkout payload');
    }

    throw new Error('Failed to create fast spring checkout session');
  }

  return response;
};

export const completeFastSpringCheckoutSession = async (
  store: string,
  bearerToken: string
): Promise<SfResponse<FastSpringCompleteSessionData>> => {
  const accountId = await getAccountId();
  const response = await commercePost<SfResponse<FastSpringCompleteSessionData>>(
    '/v1/checkout/fs/complete',
    {
      cartId: getCartId(),
      effectiveAccountId: accountId,
      store: store,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    const results = response.data;
    if (results == null) {
      throw new Error('Failed to create fast spring checkout session');
    }

    throw new Error('Failed to create checkout session');
  }

  return response;
};

export const createEncryptedFastSpringCheckoutSession = async (
  billingDetails: FastSpringContactAddress,
  store: string,
  bearerToken: string
): Promise<FastSpringEncryptedData> => {
  const accountId = await getAccountId();

  const body = {
    store: store,
    billingDetails: billingDetails,
    bearerToken: bearerToken,
    cartId: getCartId(),
    accountId: accountId,
  };

  const response = await fetch(`/api/checkout/secure`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (isNonOkResponse(response)) {
    throw new Error(response.statusText);
  }

  const data = await response.json();

  return data;
};
