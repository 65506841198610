import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import {
  createEncryptedFastSpringCheckoutSession as createEncryptedFastSpringCheckoutSessionApi,
  completeFastSpringCheckoutSession as completeFastSpringCheckoutSessionApi,
} from './checkout-api';
import { FastSpringEncryptedData } from './checkout-models';
import { deleteStoredCartId } from 'components/cart/cart-provider';
export interface InitialStateModel {
  updateCheckoutError: string | null;
  checkoutStage:
    | 'sign-in'
    | 'payment-billing'
    | 'payment-method'
    | 'confirmation'
    | 'mp-paypal-billing'
    | 'mp-checkout-summary';
  billingAddress: PaymentAddress | null;
  shippingAddress: PaymentAddress | null;
  error: string | null;
  fastSpringCheckoutStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fastSpringStoreBuilderKeys: FastSpringEncryptedData | null;
  salesforceOrderId: string | null;
  fastSpringTaxData: FastSpringCallbackData | null;
  fastSpringEmbeddedStoreStatus: 'idle' | 'loading' | 'succeeded';
  eulaTermsAccepted: boolean;
}

export type PaymentAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode: string;
  country: string;
  phoneNumberExtension?: string;
  phoneNumber: string;
};

export type FastSpringContactAddress = {
  email: string | null | undefined;
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1: string;
  addressLine2: string | undefined;
  city: string | undefined;
  region: string | undefined;
  country: string;
  postalCode: string;
};

export type FastSpringCallbackData = {
  totalValue: number;
  taxValue: number;
  totalWithTaxValue: number;
  subtotalValue: number;
  groups: {
    items: {
      selected: boolean;
    }[];
  }[];
};

const initialState: InitialStateModel = {
  updateCheckoutError: null,
  checkoutStage: 'payment-billing',
  billingAddress: null,
  shippingAddress: null,
  error: null,
  fastSpringCheckoutStatus: 'idle',
  fastSpringStoreBuilderKeys: null,
  salesforceOrderId: null,
  fastSpringTaxData: null,
  fastSpringEmbeddedStoreStatus: 'idle',
  eulaTermsAccepted: false,
};

export const createEncryptedFastSpringCheckoutSession = createDebouncedAsyncThunk(
  'checkout/createEncryptedFastSpringCheckoutSession',
  async (data: { billingDetails: FastSpringContactAddress; store: string; bearerToken: string }) =>
    await createEncryptedFastSpringCheckoutSessionApi(
      data.billingDetails,
      data.store,
      data.bearerToken
    ),
  150
);

export const completeFastSpringCheckoutSession = createDebouncedAsyncThunk(
  'checkout/completeFastSpringCheckoutSession',
  async (data: { store: string; bearerToken: string }) =>
    await completeFastSpringCheckoutSessionApi(data.store, data.bearerToken),
  150
);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setBillingAddress: (state, action: PayloadAction<PaymentAddress | null>) => {
      state.billingAddress = action.payload;
    },
    setCheckoutStage: (
      state,
      action: PayloadAction<
        | 'payment-billing'
        | 'mp-paypal-billing'
        | 'payment-method'
        | 'confirmation'
        | 'mp-checkout-summary'
      >
    ) => {
      state.checkoutStage = action.payload;
    },
    setFastSpringEmbeddedStoreStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'succeeded'>
    ) => {
      state.fastSpringEmbeddedStoreStatus = action.payload;
    },
    setFastSpringTaxData: (state, action: PayloadAction<FastSpringCallbackData>) => {
      state.fastSpringTaxData = action.payload;
    },
    setEulaTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.eulaTermsAccepted = action.payload;
    },
    updateCheckoutError: (state, action: PayloadAction<string | null>) => {
      state.updateCheckoutError = action.payload;
      state.error = action.payload;
    },
    removeFastSpringStoreBuilderKeys: (state) => {
      state.fastSpringStoreBuilderKeys = null;
      state.fastSpringEmbeddedStoreStatus = 'idle';
      state.fastSpringCheckoutStatus = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createEncryptedFastSpringCheckoutSession.pending, (state) => {
        state.fastSpringCheckoutStatus = 'loading';
        state.fastSpringStoreBuilderKeys = null;
      })
      .addCase(createEncryptedFastSpringCheckoutSession.fulfilled, (state, action) => {
        state.fastSpringStoreBuilderKeys = action.payload;
        state.fastSpringCheckoutStatus = 'succeeded';
        state.error = null;
      })
      .addCase(createEncryptedFastSpringCheckoutSession.rejected, (state) => {
        state.fastSpringCheckoutStatus = 'failed';
        state.fastSpringStoreBuilderKeys = null;
        state.error = 'Something went wrong';
      })
      .addCase(completeFastSpringCheckoutSession.pending, (state) => {
        state.fastSpringCheckoutStatus = 'loading';
      })
      .addCase(completeFastSpringCheckoutSession.fulfilled, (state, action) => {
        state.fastSpringCheckoutStatus = 'succeeded';
        state.salesforceOrderId = action.payload.data.sfOrderNumber;
        deleteStoredCartId();
      })
      .addCase(completeFastSpringCheckoutSession.rejected, (state) => {
        state.fastSpringCheckoutStatus = 'failed';
        state.error = 'Something went wrong';
      });
  },
});

export const {
  setCheckoutStage,
  setBillingAddress,
  setFastSpringTaxData,
  setFastSpringEmbeddedStoreStatus,
  setEulaTermsAccepted,
  updateCheckoutError,
  removeFastSpringStoreBuilderKeys,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
